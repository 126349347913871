<script setup lang="ts">
import type { INewsletter } from '~/types'

const form = ref<INewsletter>({
  email: '',
})

const toast = useToast()
const { t } = useI18n()
const loadingCreate = ref(false);

function resetForm() {
  form.value = {
    email: '',
  }
}

async function handleFormSubmit() {
  if (form.value.email === '') {
    toast.add({
      id: "error",
      color: "red",
      icon: "i-heroicons-x-circle",
      title: t('newsletter.an-error-occurred'),
      description: t('newsletter.please-fill-all-fields'),
    })
    return;
  }
  loadingCreate.value = true;
  await useFetch("/api/newsletters/createNewsletter", {
    method: "POST",
    body: form.value,
    onResponse({response}) {
      if (response.status === 200) {
        toast.add({
          id: "success",
          color: "green",
          icon: "i-heroicons-check-circle",
          title: t('newsletter.success'),
          description: t('newsletter.you-have-successfully-signed-up'),
        })
        resetForm();
      } else if (response.status === 409) {
        toast.add({
          id: "error",
          color: "red",
          icon: "i-heroicons-x-circle",
          title: t('newsletter.an-error-occurred'),
          description: t('newsletter.you-are-already-subscribed'),
        })
        resetForm();
      } else {
        toast.add({
          id: "error",
          color: "red",
          icon: "i-heroicons-x-circle",
          title: t('newsletter.an-error-occurred'),
          description: t('newsletter.please-try-again'),
        })
        resetForm();
      }
    }
  });

  loadingCreate.value = false;
}
</script>

<template>
  <div class="h-screen-1/2">
    <div class="sm:mx-40 h-full flex flex-col sm:flex-row justify-between items-center">
      <div class="sm:w-5/12 w-11/12 h-3/5 bg-gray-100 rounded-lg shadow-xl flex items-center my-4 sm:my-0">
        <div class="w-11/12 sm:w-2/3 sm:h-3/5 flex flex-col items-left justify-around mx-auto">
          <span class="text-left font-bold">{{ $t('newsletter.title') }}</span>
          <span class="text-left text-gray-500">{{ $t('newsletter.description') }}</span>
          <form @submit.prevent="handleFormSubmit" class="flex justify-between flex-col sm:flex-row space-y-2 sm:space-y-0">
            <input v-model="form.email" type="email" :placeholder="t('newsletter.email')" class="sm:w-8/12 h-10 text-sm border border-gray-300 focus:ring-2 focus:outline-none focus:ring-ocean-950 rounded-lg px-4">
            <UButton v-if="loadingCreate" loading>{{ $t('newsletter.loading') }}</UButton>
            <UButton v-if="!loadingCreate" type="submit" class="w-auto mx-auto">{{ $t('newsletter.sign-up') }}</UButton>
          </form>
        </div>
      </div>
      <div class="sm:w-5/12 w-11/12 h-3/5 bg-gray-100 rounded-lg shadow-xl flex items-center my-4 sm:my-0">
        <div class="relative overflow-hidden bg-gray-900 shadow-xl h-full w-full rounded-lg">
          <NuxtImg class="absolute inset-0 h-full w-full object-cover" src="/assets/contact.webp" alt="" />
          <div class="absolute inset-0 bg-ocean-950/60" />
          <div class="relative mx-auto max-w-2xl text-white text-center h-full flex flex-col justify-center items-center space-y-4">
            <h2 class="text-2xl font-bold">{{ $t('home.contact-title') }}</h2>
            <div class="text-left m-0 mx-6 sm:mx-0">
              <p>{{ $t('home.contact-description-first') }}</p>
              <p>{{ $t('home.contact-description-second') }}</p>
            </div>
            <NuxtLink :to="localePath('/contact')" class="font-semibold">{{ $t('home.contact-button') }} <span class="transition-transform duration-300 ease-in-out hover:rotate-90">➜</span></NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
